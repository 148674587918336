import React, { useState, useEffect, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  TTimeout,
  Header,
  Search,
  Loadable,
  DatePicker,
  TableInfo,
  TableEmpty,
  NavFilter,
  useDatePickerState,
  useApiGet,
  useApiMore,
  useSortableTable,
  useTableEmpty,
  formatFilterRecords,
  map,
  plural,
  newTableSort,
  isBlank,
  varClass,
  formatStat,
  navigateTo,
  useSnackbar,
  AssigneeList,
  IProfileV1Users,
} from '../../shared'
import { getRequest } from '../../../services'

const perPage = 20

const filterLabels = {
  date: 'Date',
  discoverTargetDomain: 'Target domain',
  discoverChannelUuid: 'Site',
  discoverTargetUrlPath: 'Target url',
  discoverUrlPath: 'Page URL',
  assigneeUserUuids: 'Managers',
}

const clickSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'groupName') {
    return [a.groupName || a.group || '', b.groupName || b.group || '']
  }
})

export const OtherClicks = (props) => {
  const datePickerState = useDatePickerState()

  const [tab, setTab] = useState('discoverTargetDomain')
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState([])

  const channels = useApiGet('/channels/v1/list')
  const assignees = useApiGet('/advs/v1/assignees')
  const users = useApiGet<IProfileV1Users[]>('/profile/v1/users')

  const { data, loading, loaded, hasMore, loadMore } = useApiMore(
    '/discovers/v1/others/insights',
    (offset: number) => {
      const result: any = {
        groupBy: tab,
        fromDate: datePickerState.range.startDate,
        toDate: datePickerState.range.endDate,
        search: search
          .toLowerCase()
          .replace(/https?:\/\//, '')
          .replace(/^www\./, '')
          .replace(/[/?#].*$/, ''),
        offset,
        limit: perPage,
      }

      for (const filter of filters) {
        if (filter.value) {
          if (filter.key === 'date') {
            result.fromToDate = new Date(filter.value)
          } else if (filter.key === 'assigneeUserUuids') {
            // NOP
          } else {
            result[filter.key] = filter.value
          }
        }
      }

      return result
    },
    {
      perPage,
      resetDeps: [
        search,
        JSON.stringify(filters),
        tab,
        datePickerState.range.startDate,
        datePickerState.range.endDate,
      ],
    }
  )

  return (
    <>
      <Header label="Discover clicks" />
      <Nav {...{ filters, setFilters, channels, users }} />
      <Loadable data={loaded && assignees} placeholder={<Placeholder />}>
        <Table
          {...{
            data,
            hasMore,
            loadMore,
            loading,
            tab,
            setTab,
            search,
            setSearch,
            assignees,
            filters,
            setFilters,
          }}
        />
      </Loadable>
    </>
  )
}

const Nav = (props) => {
  const { filters, setFilters, channels, users } = props

  return (
    <>
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions: {
              discoverChannelUuid: {
                '': 'All sites',
                ...formatFilterRecords(
                  channels,
                  'channelUuid',
                  'channelName',
                  'channelDomain'
                ),
              },
              assigneeUserUuids: formatFilterRecords(
                users,
                'userUuid',
                (user) => `${user.userFirstname} ${user.userLastname}`
              ),
            },
          }}
          hideChecks
        />
      </div>
      <DatePicker className="in-header" />
    </>
  )
}

const Table = (props) => {
  const {
    data,
    hasMore,
    loadMore,
    loading,
    tab,
    setTab,
    search,
    setSearch,
    assignees,
    filters,
    setFilters,
  } = props

  const [shownSearch, setShownSearch] = useState(search)
  const searchRef = useRef<TTimeout>()
  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'discoverCount',
    dir: 'desc',
  })

  const { showSnackbar } = useSnackbar()

  const total = data.reduce((result, link) => result + link.discoverCount, 0)
  const headProps = { sort, dir, toggleSort }
  const itemProps = {
    total,
    tab,
    sort,
    assignees,
    filters,
    setFilters,
    showSnackbar,
  }

  const assigneeFilter = filters.find(
    (filter) => filter.key === 'assigneeUserUuids' && filter.value
  )
  const shownLinks = clickSort(
    data.filter((link) => {
      const domain = link.groupName || link.group
      if (!domain) return false
      if (assigneeFilter && tab === 'discoverTargetDomain') {
        const assigneeUserUuids = assigneeFilter.value
          .split(',')
          .map((uuid) => uuid.trim())
          .filter(Boolean)
        return assignees.find(
          (assignee) =>
            assignee.assigneeAdvertiserDomain === domain &&
            assigneeUserUuids.includes(assignee.assigneeUserUuid)
        )
      }
      return true
    }),
    sort,
    dir
  )
  const [isEmpty, setIsEmpty] = useTableEmpty(data)

  return (
    <>
      <div className="card discover-other-card">
        <div className="page-subnav">
          <div className="nav">
            <ul>
              {map(
                {
                  'date': 'Dates',
                  'discoverTargetDomain': 'Target domain',
                  'discoverChannelUuid': 'Sites',
                  'discoverTargetUrlPath': 'Target path',
                  'discoverUrlPath': 'Page URL',
                },
                (value, label) => (
                  <li key={value} className={tab === value ? 'active' : ''}>
                    <button className="link" onClick={() => setTab(value)}>
                      {label}
                    </button>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>

        <div className="card-divider" />
        <div className="card-body card-body-fill">
          <TableInfo>
            <Search
              value={shownSearch}
              setValue={setShownSearch}
              placeholder="Find media"
              className="search flex-1"
            />
            <div className="total">
              {data?.length || 0} {plural(data?.length, 'result')}
            </div>
          </TableInfo>

          <div className="table-container-overflow">
            <table className="table table-bordered table-sortable table-hoverable">
              <thead>
                <tr>
                  <TableHead
                    value="groupName"
                    label="Media"
                    className={varClass({
                      'w-50': tab === 'discoverTargetDomain',
                      'w-70': tab !== 'discoverTargetDomain',
                    })}
                    {...headProps}
                  />
                  {tab === 'discoverTargetDomain' && (
                    <TableHead
                      value=""
                      label="Assigned"
                      className="w-20"
                      {...headProps}
                    />
                  )}
                  <TableHead
                    value="discoverCount"
                    label="Clicks"
                    desc
                    className="w-30"
                    {...headProps}
                  />
                </tr>
              </thead>
              <tbody className="text-nowrap">
                {map(shownLinks, (link, index) => (
                  <TableItem key={index} link={link} {...itemProps} />
                ))}
                {!isEmpty && isBlank(shownLinks) && !loading && (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-light text-bolder text-center"
                    >
                      Nothing found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {isEmpty && (
            <TableEmpty
              icon="discover"
              title="No data available yet"
              subtitle="To see your Discover Clicks simply create a Site and add the Tracking script"
              buttons={{
                '/channels': 'Create site',
              }}
              setIsEmpty={setIsEmpty}
            />
          )}
        </div>
      </div>

      {hasMore && (
        <button className="btn btn-lg btn-link m-t-5" onClick={loadMore}>
          Load more
        </button>
      )}
    </>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort, className } = props

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
        [className]: !!className,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const {
    link,
    total,
    tab,
    sort,
    assignees,
    filters,
    setFilters,
    showSnackbar,
  } = props

  return (
    <tr>
      <td
        className={varClass({
          'text-dark text-bold': true,
          'sort-highlight': sort === 'discoverTargetDomain',
        })}
      >
        <div className="name row row-space-between">
          <span>{link.groupName || link.group || link.grp || 'Unknown'}</span>
          <div className="row row-narrow row-nowrap">
            {tab === 'discoverTargetDomain' && (
              <button
                className="link link-lighter"
                onClick={async (event) => {
                  if (link.groupName || link.group || link.grp) {
                    const response = await getRequest(
                      `/advs/v1/uuid/${
                        link.groupName || link.group || link.grp
                      }`
                    )
                    if (response.data) {
                      navigateTo(event, `/merchants/${response.data}`)
                    } else {
                      showSnackbar('Merchant not found')
                    }
                  } else {
                    showSnackbar('Merchant not found')
                  }
                }}
              >
                <SVG
                  src="/images/icon-details.svg"
                  className="vertical-middle"
                />
              </button>
            )}
            <button
              className="link link-lighter"
              onClick={() => {
                const key = tab
                const value = link.group || ''
                const label = link.groupName || link.group || 'Unknown'

                const newFilters = [...filters]
                const existingFilter = newFilters.find(
                  (filter) => filter.key === key
                )
                if (existingFilter) {
                  existingFilter.key = key
                  existingFilter.value = value
                  existingFilter.label = label
                } else {
                  newFilters.push({ key, value, label })
                }
                setFilters(newFilters)
              }}
            >
              <SVG src="/images/icon-filter.svg" className="vertical-middle" />
            </button>
          </div>
        </div>
      </td>
      {tab === 'discoverTargetDomain' && (
        <td>
          <AssigneeList
            domain={link.groupName || link.group}
            assignees={assignees}
          />
        </td>
      )}
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'discoverCount',
        })}
      >
        <div className="value-badge value-badge-clickCount">
          {formatStat(link.discoverCount)}
        </div>
        <div className="discover-bar">
          <div
            style={{
              width: `${(total ? link.discoverCount / total : 0) * 100}%`,
            }}
          />
        </div>
      </td>
    </tr>
  )
}
