import React, { useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { uniq } from 'lodash'
import { postRequest } from '../../../services'
import { useArray } from '../../shared'
import { Collapse } from '../../shared/elements/Collapse'
import SVG from 'react-inlinesvg'

export const CreatorModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props

  const [initialCreatorsDefault, initialCreatorsOnly] = useMemo(() => {
    return [
      campaign.trackers.every((tracker) => tracker.trackerIsCreatorDefault),
      campaign.trackers.every((tracker) => tracker.trackerIsCreatorOnly),
    ]
  }, [campaign])

  const [creatorsDefault, setCreatorsDefault] = useState(initialCreatorsDefault)
  const [creatorsOnly, setCreatorsOnly] = useState(initialCreatorsOnly)

  const trackerUuids = useMemo(
    () => campaign.trackers.map((tracker) => tracker.trackerUuid),
    campaign
  )

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const onSubmit = async () => {
    setSubmitting(true)
    setError('')

    const params = {
      trackerUuids,
      trackerIsCreatorDefault: creatorsDefault,
      trackerIsCreatorOnly: creatorsOnly,
    }
    const response = await postRequest(`/advs/v1/camps/creators`, params)

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
  }
  const onDisable = async () => {
    setSubmitting(true)
    setError('')

    const response = await postRequest(`/advs/v1/camps/creators`, {
      trackerUuids,
      trackerIsCreatorOnly: false,
    })

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          Edit creator options
        </div>
      </div>

      <div className="card-body">
        <div className="flex-1">
          <div className="m-b-4">
            <div className="m-b-1">
              <span className="text-light m-r-1">Network:</span>
              <span className="text-bold text-dark">
                {campaign.network.networkName}
              </span>
            </div>
            <div className="m-b-1">
              <span className="text-light m-r-1">Campaign:</span>
              <span className="text-bold text-dark">
                {campaign.campaign.campaignName}
              </span>
              <span className="m-l-1 text-light">
                ({trackerUuids.length} domain
                {trackerUuids.length === 1 ? '' : 's'})
              </span>
            </div>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorsDefault}
                onChange={(event) => setCreatorsDefault(event.target.checked)}
              />
              <label>Default creator campaign</label>
            </div>
            <span
              data-tip="Creators will be mapped to this campaign by default"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorsOnly}
                onChange={(event) => setCreatorsOnly(event.target.checked)}
              />
              <label>Creators only</label>
            </div>
            <span
              data-tip="Only clicks from creators will be able to use this campaign"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <ReactTooltip
            id="creator-tooltip"
            type="dark"
            effect="solid"
            place="right"
          />
        </div>
        <div className="row row-space-between row-center row-nowrap m-t-4">
          <div className="text-red max-w-50">{error}</div>
          <div className="row">
            <button
              className="btn btn-dark"
              disabled={submitting}
              onClick={onSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
