import React from 'react'
import { Router, Redirect } from '@reach/router'
import { PageProps } from 'gatsby'
import { useNavigateReload } from '../components/shared/helpers'
import { Layout } from '../components/layouts/application'
import { Overview } from '../components/overview2'
// import { Activities } from '../components/activities'
// import { GetStarted } from '../components/get-started'
import { InsightStatistics } from '../components/insights/statistics'
import { InsightConversions } from '../components/insights/conversions'
import { InsightClicks } from '../components/insights/clicks'
import { Advertisers } from '../components/advertisers'
import { Advertiser } from '../components/advertisers/show'
import { Merchants2 } from '../components/merchants2'
import { Merchant2 } from '../components/merchants2/show'
import { Recommendations } from '../components/discover/recommendations'
import { NetworkClicks } from '../components/discover/network'
// import { Opportunities } from '../components/discover/opportunities'
import { Opportunities2 } from '../components/discover/opportunities2'
import { BrokenLinks } from '../components/discover/broken'
import { OtherClicks } from '../components/discover/other'
import { UrlShortener } from '../components/tools/shortener'
// import { Webhooks } from '../components/tools/webhooks'
import { Channels } from '../components/channels'
import { Channel } from '../components/channels/show'
import { Sites } from '../components/sites'
import { Site } from '../components/sites/show'
// import { Autotrack } from '../components/autotrack'
import { Networks } from '../components/networks'
import { Network } from '../components/networks/show'
import { Partners } from '../components/partners'
import { Partner } from '../components/partners/show'
import { Profile } from '../components/settings2/profile'
import { Billing } from '../components/settings2/billing'
import { Network2 } from '../components/network2'
import { NetworkDetailV2 } from '../components/network2/show'
import { SmartLinks } from '../components/smartlinks'
// import { Help } from '../components/help'

export default function IndexPage(props: PageProps) {
  const render = useNavigateReload(props.location.state)

  return (
    <Layout>
      {render ? (
        <Router>
          <Overview path="/" />
          <InsightStatistics path="/insights/reports" />
          <InsightStatistics path="/insights/plugin" origin="plugin" />
          <InsightConversions path="/insights/conversions" />
          <InsightClicks path="/insights/clicks" />
          <Advertisers path="/advs" />
          <Advertiser path="/advs/:advertiserUuid" />
          <Merchants2 path="/merchants" />
          <Merchant2 path="/merchants/:advertiserUuid" />
          <Recommendations path="/discover/recommendations" />
          <Opportunities2 path="/discover/opportunities" />
          <NetworkClicks path="/discover/clicks" />
          <OtherClicks path="/discover/other" />
          <BrokenLinks path="/discover/broken" />
          <UrlShortener path="/tools/urls" />
          <SmartLinks path="/smartlinks" />
          <Channels path="/channels" />
          <Channel path="/channels/:channelUuid" />
          <Sites path="/sites" />
          <Site path="/sites/:channelUuid" />
          <Networks path="/networks" />
          <Network2 path="/network2" />
          <Network path="/networks/:networkUuid" />
          <NetworkDetailV2 path="/network2/:networkUuid" />
          <Partners path="/partners" />
          <Partner path="/partners/:partnerUuid" />
          <Profile path="/settings/profile" />
          <Billing path="/settings/billing" />
          <Redirect from="*" to="/" noThrow />
        </Router>
      ) : null}
    </Layout>
  )
}
