import React, { useState, useRef, useMemo } from 'react'
import { toUnicode } from 'punycode'
import { useOutsideClick, varClass, map, isBlank } from '../../shared'
import { sortBy } from 'lodash'
import SVG from 'react-inlinesvg'

export const MultiSelectDropdown = (props) => {
  const {
    label,
    emptyLabel,
    values,
    sortKey,
    uuidGetter,
    labelGetter,
    selectedValues,
    toggleSelectedValue,
    addAllValues,
    clearSelectedValues,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [search, setSearch] = useState('')
  const showSearch = values.length > 5

  const options = useMemo(
    () =>
      sortBy(values, sortKey).map((value) => ({
        value: uuidGetter(value),
        label: labelGetter(value),
      })),
    [values]
  )
  const shownOptions = useMemo(() => {
    if (search) {
      return options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    } else {
      return options
    }
  }, [options, search])

  return (
    <div className="subnav-filter w-100" ref={ref}>
      <button
        className={varClass({
          'filter-button text-primary m-r-15 merchant-filter-button': true,
          'active': open,
        })}
        onClick={() => setOpen(!open)}
      >
        <>
          {label}
          {': '}
          <b className="m-l-1">
            {selectedValues.length === 1
              ? options.find((option) => option.value === selectedValues[0])
                  ?.label || 'Deleted user'
              : selectedValues.length > 1
              ? `${selectedValues.length} ${label.toLowerCase()} selected`
              : emptyLabel || 'All'}
          </b>
        </>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-1': true,
            'rotate-180': open,
          })}
        />
      </button>
      <div
        className={varClass({
          'submenu w-100 options': true,
          'shown': open,
        })}
      >
        {showSearch && (
          <div className="control control-focusless">
            <SVG src="/images/icon-search.svg" />
            <input
              type="text"
              placeholder={`Find ${label.toLowerCase().replace(/s$/, '')}`}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        )}
        <ul>
          {map(shownOptions, (option) => (
            <li
              key={option.value}
              title={toUnicode(option.label)}
              className={varClass({
                'selected': selectedValues.includes(option.value),
              })}
              onClick={() => {
                toggleSelectedValue(option.value)
              }}
            >
              {toUnicode(option.label)}
            </li>
          ))}
          {isBlank(shownOptions) && (
            <li className="text-light text-bold text-center inactive">
              Nothing found
            </li>
          )}
        </ul>
        {selectedValues.length > 0 ? (
          <button
            className="remove-btn link"
            onClick={() => {
              clearSelectedValues()
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Clear
          </button>
        ) : addAllValues ? (
          <button
            className="remove-btn link"
            onClick={() => {
              addAllValues(values.map(uuidGetter))
            }}
          >
            <SVG src="/images/icon-plus2-color.svg" className="m-r-2" />
            All
          </button>
        ) : null}
      </div>
    </div>
  )
}
