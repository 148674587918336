import React, { useState, useEffect, useRef, useMemo } from 'react'
import { uniq, flatten, orderBy } from 'lodash'
import SVG from 'react-inlinesvg'
import ReactTooltip from 'react-tooltip'
import { useForm, Controller } from 'react-hook-form'
import SmoothCollapse from 'react-smooth-collapse'
import moment from 'moment'
import {
  Loadable,
  MiniAreaChart,
  Header,
  Commission,
  Trend,
  DateInput,
  WithTooltip,
  useApiGet,
  useModal,
  useSnackbar,
  useFormSubmit,
  formatCommission,
  formatStat,
  formatShortDateStr,
  navigateTo,
  navigateReload,
  map,
  useArray,
  varClass,
  isBlank,
  capitalize,
  plural,
  responseError,
  copyToClipboard,
  currencies as defaultCurrencies,
  CountrySelectOptions,
  CountryPhoneInput,
  ICurrenciesV1List,
} from '../shared'
import {
  getCurrentUser,
  postRequest,
  connectGet,
  connectPost,
  connectPut,
  connectDelete,
  connectPatch,
} from '../../services'

const onboardHost = process.env.ONBOARD_HOST
const backendHost = process.env.BACKEND_HOST
const statusLabels = {
  pending: 'Waiting for the merchant to implement and test tracking',
  sent: 'Waiting for the merchant to implement and test tracking',
  signed: 'Waiting for the merchant to implement and test tracking',
  installed: 'Waiting for the merchant to implement and test tracking',
  // pending: 'Pending',
  // sent: 'Contract sent',
  // signed: 'Ready',
  // installed: 'Tracking installed',
  running: 'Running',
  ended: 'Ended',
}

const attributionModelLabels = {
  'default': 'Attribute all in cookie period',
  'lastKnown': 'Last click from known sources',
  'session': 'Last click from all sources',
}

const calculateTopChartTrend = (before: number, after: number): number => {
  if (before > 0 && after > 0) {
    return (after / before) * 100 - 100
  } else if (before === 0 && after === 0) {
    return 0
  } else if (before === 0) {
    return 100
  } else if (after === 0) {
    return -100
  } else {
    return 0
  }
}

type TopChartData = [number, number, number[]] // total, trend, graph values
function calculateTopChartData(stats: any[], key: string): TopChartData {
  if (!stats || !stats.length) return [0, 0, [0, 0, 0, 0, 0, 0, 0]]

  const halfIndex = Math.floor(stats.length / 2)
  const prevStats = stats.slice(0, halfIndex)
  const currentStats = stats.slice(halfIndex)

  const prevTotal = prevStats.reduce(
    (result: number, stat: any) => result + parseFloat(stat[key]),
    0
  )
  const currentTotal = currentStats.reduce(
    (result: number, stat: any) => result + parseFloat(stat[key]),
    0
  )
  const trend = calculateTopChartTrend(prevTotal, currentTotal)
  const graph: number[] = []

  for (const stat of currentStats) {
    graph.push(parseFloat(stat[key]))
  }

  return [currentTotal, trend, graph]
}

export const Partner = (props) => {
  const { partnerUuid } = props

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [partner, setPartner] = useState(null)
  const [partnerUsers, setPartnerUsers] = useState(null)
  const [data, setData] = useState(null)
  useEffect(() => {
    const callback = async () => {
      const currentUser = getCurrentUser()
      const response = await connectGet(
        `/publishers/${currentUser.account?.accountUuid}/${partnerUuid}`
      )
      setPartner(response?.data?.partner || {})
      setPartnerUsers(response?.data?.users || [])
      setData(response?.data?.contracts || [])
    }
    callback()
  }, [partnerUuid])

  const currencies = useApiGet<ICurrenciesV1List[]>('/currencies/v1/list')
  const connectedChannels = useApiGet(
    '/channels/v2/channels?networkIdentifier=heylinkinbound'
  )

  const availableCurrencies = useMemo(() => {
    const result: string[] = []
    if (currencies?.length) {
      result.push(...currencies.map((currency) => currency.currencyCode))
    } else {
      result.push(...defaultCurrencies)
    }
    if (data?.length) {
      result.push(...data.map((campaign) => campaign.currency))
    }
    return uniq(result)
  }, [currencies, data])

  const initialHashRef = useRef(location.hash)

  return (
    <>
      <Header backPath="/partners" />
      <Loadable
        data={partner && data && currencies && connectedChannels}
        placeholder={null}
      >
        <Content
          {...{
            initialHashRef,
            partner,
            partnerUsers,
            data,
            currencies: availableCurrencies,
            showModal,
            showSnackbar,
            connectedChannels,
          }}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const {
    initialHashRef,
    partner,
    partnerUsers,
    data,
    currencies,
    showModal,
    showSnackbar,
    connectedChannels,
  } = props

  const contacts = uniq(
    [
      partner.company?.contactName || '',
      ...data.map((row) => row.contactName),
    ].filter((str) => !!str)
  )
  const contactEmails = uniq(
    [
      partner.email,
      partner.company?.email,
      partner.company?.contactEmail,
      partner.billing?.email,
      partner.billing?.contactEmail,
      // ...data.map((row) => row.contactEmail), // FIXME: Hide for now, contract.contactEmail needs to be fixed on backend
    ].filter((str) => !!str)
  )
  const contactPhones = uniq(
    [
      partner.company?.phone,
      partner.company?.contactPhone,
      partner.billing?.phone,
      partner.billing?.contactPhone,
      ...data.map((row) => row.contactPhone),
    ].filter((str) => !!str && str.length > 3)
  )
  const companyVat = uniq(
    [
      partner.company?.vat,
      partner.company?.companyVat,
      partner.billing?.vat,
      partner.billing?.companyVat,
    ].filter((str) => !!str)
  )[0]
  const isError =
    !partner.isCreated &&
    data.length &&
    data.every((row) => row.tuneStatus === 'failed')

  useEffect(() => {
    if (initialHashRef?.current === '#new-campaign') {
      initialHashRef.current = ''
      showModal(<NewCampaignModal partner={partner} currencies={currencies} />)
    }
  }, [])

  return (
    <div className="partner-show-content">
      <div className="partner-info">
        <div className="card">
          <div className="card-body p-t-10 p-b-1">
            <div className="row row-space-between">
              <div className="text-dark text-bolder m-b-4">{partner.name}</div>
              <div className="row gap-1 m-t--1">
                <button
                  className="btn btn-bordered text-small text-bold btn-xs"
                  onClick={() => {
                    showModal(<EditPartnerModal partner={partner} />)
                  }}
                  style={{ maxHeight: 24 }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-bordered-danger text-small text-bold btn-xs"
                  onClick={() => {
                    showModal(<DeletePartnerModal partner={partner} />)
                  }}
                  style={{ maxHeight: 24 }}
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="row row-fill m-b-10">
              <span className="text-light m-r-1">Date added:</span>
              <span className="text-dark">
                {formatShortDateStr(partner.createdAt)}
              </span>
            </div>
            {!isBlank(contacts) && (
              <div className="row row-fill m-b-10">
                <span className="text-light m-r-1">
                  {plural(contacts.length, 'Contact')}:
                </span>
                <span className="text-dark">
                  {map(contacts, (name, index) => (
                    <div key={index}>{name}</div>
                  ))}
                </span>
              </div>
            )}
            {!isBlank(contactEmails) ? (
              <div className="row row-fill m-b-10">
                <span className="text-light m-r-1">
                  {plural(contactEmails.length, 'Email')}:
                </span>
                <span className="text-dark">
                  {map(contactEmails, (email, index) => (
                    <div
                      key={index}
                      className="block link"
                      onClick={() => {
                        copyToClipboard(email)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      {email}
                    </div>
                  ))}
                </span>
              </div>
            ) : null}
            {!isBlank(contactPhones) ? (
              <div className="row row-fill m-b-10">
                <span className="text-light m-r-1">
                  {plural(contactPhones.length, 'Phone')}:
                </span>
                <span className="text-dark">
                  {map(contactPhones, (phone, index) => (
                    <div
                      key={index}
                      className="block link"
                      onClick={() => {
                        copyToClipboard(`+${phone}`)
                        showSnackbar('Copied to clipboard')
                      }}
                    >
                      +{phone}
                    </div>
                  ))}
                </span>
              </div>
            ) : null}
            {companyVat && (
              <div className="row row-fill m-b-10">
                <span className="text-light m-r-1">VAT:</span>
                <span className="text-dark">
                  <div
                    className="block link"
                    onClick={() => {
                      copyToClipboard(companyVat)
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    {companyVat}
                  </div>
                </span>
              </div>
            )}
            {isError && (
              <div className="row row-fill m-b-10">
                <span className="text-new-blood">
                  <div>Incorrect merchant account</div>
                </span>
              </div>
            )}

            <PartnerUsers
              partner={partner}
              users={partnerUsers}
              showModal={showModal}
            />

            <Timeline
              partner={partner}
              data={data}
              showSnackbar={showSnackbar}
            />
          </div>
        </div>
      </div>

      <div className="partner-data">
        <button
          className="partner-add-campaign flex-center btn btn-white w-100 m-b-20"
          onClick={() => {
            showModal(
              <NewCampaignModal
                partner={partner}
                currencies={currencies}
                hash={initialHashRef.current}
              />
            )
          }}
        >
          <SVG src="/images/icon-plus-color.svg" className="m-r-15" /> Add new
          campaign
        </button>

        {map(data, (row) => (
          <Contract
            key={row.uuid}
            partner={partner}
            row={row}
            currencies={currencies}
            showSnackbar={showSnackbar}
            showModal={showModal}
            connectedChannels={connectedChannels}
            hash={initialHashRef.current}
          />
        ))}
      </div>
    </div>
  )
}

const PartnerUsers = (props) => {
  const { partner, users, showModal } = props

  return (
    <>
      <div className="card-divider" />
      <div className="row row-center row-space-between m-b-2">
        <div className="text-dark text-bold">Partner users</div>
        <button
          className="btn btn-white btn-sm text-small"
          onClick={() => {
            showModal(<NewUserModal partner={partner} />)
          }}
        >
          Add
        </button>
      </div>

      <div className="partner-users">
        {map(users, (user) => (
          <div
            key={user.email}
            className="row row-center row-space-between m-t-2"
          >
            <div className="partner-user">
              {user.name ? (
                <>
                  <span className="text-dark">{user.name}</span>
                  <span className="text-small m-l-1">({user.email})</span>
                </>
              ) : (
                <span className="text-dark">{user.email}</span>
              )}
            </div>
            <div className="row gap-1 m-t--1">
              <button
                className="btn btn-bordered text-small text-bold btn-xs"
                onClick={() => {
                  showModal(<UpdateUserModal partner={partner} user={user} />)
                }}
                style={{ maxHeight: 20 }}
              >
                Edit
              </button>
              <button
                className="btn btn-bordered-danger text-small text-bold btn-xs"
                onClick={() => {
                  showModal(<DeleteUserModal partner={partner} user={user} />)
                }}
                style={{ maxHeight: 20 }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const Timeline = (props) => {
  const { partner, data, showSnackbar } = props

  const currentUser = getCurrentUser()

  const [sentMessages, setSentMessages] = useState([])
  const [updatedMessages, setUpdatedMessages] = useState([])
  const [deletedMessages, setDeletedMessages] = useState([])

  const [message, setMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const sendMessage = async () => {
    if (submitting) return
    if (!message || !message.trim()) return
    setSubmitting(true)
    const response = await connectPost(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/note`,
      {
        author: `${currentUser.user?.userFirstname} ${currentUser.user?.userLastname}`,
        message,
      }
    )
    if (response?.message === 'success') {
      setMessage('')
      setSentMessages([response.data.activity, ...sentMessages])
    } else {
      showSnackbar(responseError(response), { type: 'alert' })
    }
    setSubmitting(false)
  }

  const updateMessage = async (uuid: number, message: string) => {
    const response = await connectPut(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/note/${uuid}`,
      {
        author: `${currentUser.user?.userFirstname} ${currentUser.user?.userLastname}`,
        message,
      }
    )
    setUpdatedMessages([...updatedMessages, response.data.activity])
  }

  const removeMessage = async (uuid: number) => {
    if (confirm('Are you sure?')) {
      setDeletedMessages([...deletedMessages, uuid])
      await connectDelete(
        `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/note/${uuid}`
      )
    }
  }

  const activities = useMemo(() => {
    let activities = [
      ...sentMessages,
      ...orderBy(
        flatten(data.map((row) => row.activities)),
        'createdAt',
        'desc'
      ),
    ]
    updatedMessages.forEach((updatedMessage) => {
      const activity = activities.find(
        (activity) => activity.uuid === updatedMessage.uuid
      )
      if (activity) {
        activity.message = updatedMessage.message
      }
    })
    activities = activities.filter(
      (activity) => !deletedMessages.includes(activity.uuid)
    )
    return activities
  }, [sentMessages, updatedMessages, deletedMessages, data])

  if (isBlank(data)) return null

  return (
    <>
      <div className="card-divider" />
      <div className="text-dark text-bold m-b-4">Timeline</div>

      <div className="control control-focusless partner-note w-100 m-b-4">
        <div className="text-light text-bold text-smaller m-b-2">Add note</div>
        <input
          value={message}
          onChange={(event) => {
            setMessage(event.target.value)
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              sendMessage()
            }
          }}
        />
        <button className="btn btn-white text-small" onClick={sendMessage}>
          Add
        </button>
      </div>

      <div className="partner-activities">
        {map(activities, (activity, index) => (
          <Activity
            key={activity.uuid || index}
            activity={activity}
            updateMessage={updateMessage}
            removeMessage={removeMessage}
          />
        ))}
      </div>
    </>
  )
}

const Activity = (props) => {
  const { activity, updateMessage, removeMessage } = props

  const [editing, setEditing] = useState(false)
  const [message, setMessage] = useState(
    activity.message.replace(/^.*<q>/, '').replace(/<\/q>.*$/, '')
  )

  return (
    <div className="row row-nowrap">
      <SVG src={`/images/partners/activity-${activity.type}.svg`} />
      <div className="flex-1">
        {!editing ? (
          <div
            className="text-dark"
            dangerouslySetInnerHTML={{ __html: activity.message }}
          />
        ) : (
          <div className="control control-focusless partner-note w-100">
            <input
              value={message}
              onChange={(event) => {
                setMessage(event.target.value)
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  updateMessage(activity.uuid, message)
                  setEditing(false)
                }
              }}
              onBlur={() => {
                setEditing(false)
              }}
            />
          </div>
        )}
        <div className="text-light text-smaller m-t-2">
          {moment.utc(activity.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
        </div>
        {activity.type === 'note' && !editing && (
          <div className="partner-activity-actions">
            <button
              className="btn btn-xs btn-link btn-borderless text-new-gray"
              onClick={() => setEditing(true)}
            >
              <SVG src="/images/icon-edit.svg" width={16} height={16} />
            </button>
            <button
              className="btn btn-xs btn-link btn-borderless text-new-red"
              onClick={() => removeMessage(activity.uuid)}
            >
              <SVG src="/images/icon-times.svg" width={16} height={16} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const Contract = (props) => {
  const {
    partner,
    row,
    currencies,
    showSnackbar,
    showModal,
    connectedChannels,
  } = props

  const [stats, setStats] = useState(null)
  useEffect(() => {
    const callback = async (id) => {
      const response = await postRequest('/insights/v1/partners', {
        fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        ids: [id],
      })
      setStats(response?.data || {})
    }
    if (row?.id) {
      callback(row.id)
    }
  }, [row?.id])

  return (
    <div className="card p-6 m-b-20">
      <InviteNotice
        contract={row}
        status={row.status}
        advertiserAgreed={row.formFinished}
        showSnackbar={showSnackbar}
        connectedChannels={connectedChannels}
      />

      <div className="relative">
        <div className="row row-space-between">
          <Status status={row.status} tuneStatus={row.tuneStatus} />
          <div className="options">
            <button
              className="btn btn-bordered text-small text-bold btn-xs"
              onClick={() => {
                showModal(
                  <EditCampaignModal
                    partner={partner}
                    contract={row}
                    currencies={currencies}
                  />
                )
              }}
            >
              {row?.status !== 'ended' ? 'Edit terms' : 'Reactivate campaign'}
            </button>
            {row?.status !== 'ended' && (
              <button
                className="btn btn-bordered-danger text-small text-bold btn-xs m-l-3"
                onClick={() => {
                  showModal(
                    <DeleteCampaignModal partner={partner} contract={row} />
                  )
                }}
              >
                End campaign
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="text-dark text-bolder m-t-3">{row.name}</div>
      <div className="m-t-10">{row.domain}</div>

      <div className="row row-space-between m-t-8 m-b-8">
        <div className="flex-1-4">
          <div className="text-light m-b-15">Start date:</div>
          <div className="text-dark text-bold">
            {formatShortDateStr(row.startsAt)}
          </div>
        </div>
        <div className="flex-1-4">
          <div className="text-light m-b-15">End date:</div>
          <div className="text-dark text-bold">
            {formatShortDateStr(row.endsAt) || '-'}
          </div>
        </div>
        <div className="flex-1-4">
          {row.commissionType === 'CPS' ? (
            <div className="text-light m-b-15">
              Commission <span className="o-70">{row.currency}</span>:
            </div>
          ) : (
            <div className="text-light m-b-15">Commission:</div>
          )}
          <div className="text-dark text-bold">
            <Commission
              type={row.commissionType}
              value={formatCommission(
                row.commissionType,
                row.commissionFlat,
                row.commissionPercentage,
                row.currency || 'DKK',
                true
              )}
            />
          </div>
        </div>
        <div
          className={varClass({
            'flex-1-4': true,
            'o-0': !row.couponCode,
          })}
        >
          <div className="text-light m-b-15">Coupon:</div>
          <div className="text-dark text-bold">{row.couponCode}</div>
        </div>
      </div>

      <div className="row row-space-between m-t-8 m-b-8">
        <div className="flex-1-4">
          <div className="text-light m-b-15">Created:</div>
          <div className="text-dark text-bold">
            {moment.utc(row.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
        <div className="flex-1-4">
          <div className="text-light m-b-15">Created by:</div>
          <div className="text-dark text-bold">{row.createdBy}</div>
        </div>
        <div className="flex-1-4">
          <div className="text-light m-b-15">Tracking period:</div>
          <div className="text-dark text-bold">
            {row.trackingPeriod || 30} {row.trackingPeriodType || 'days'}
          </div>
        </div>
        <div className="flex-1-4">
          <div className="text-light m-b-15">Deeplinks:</div>
          <div className="text-dark text-bold">
            {row.disableDeeplinks ? 'Disabled' : 'Enabled'}
          </div>
        </div>
      </div>

      {attributionModelLabels[row.customAttributionModel] && (
        <div className="row row-space-between m-t-8 m-b-8">
          <div className="flex-1">
            <div className="text-light m-b-15">Attribution model:</div>
            <div className="text-dark text-bold">
              {attributionModelLabels[row.customAttributionModel]}
            </div>
          </div>
        </div>
      )}

      <div className="card-divider" />
      <TopCharts previewStats={row.stats} stats={stats} />
    </div>
  )
}

const TopCharts = (props) => {
  const { stats, previewStats } = props

  const clicks = useMemo(
    () =>
      stats?.totals
        ? [
            stats.totals.clicks.count,
            stats.totals.clicks.change,
            stats.rows.map((stat) => stat.clickCount),
          ]
        : calculateTopChartData(previewStats, 'grossClicks'),
    [stats]
  )
  const conversions = useMemo(
    () =>
      stats?.totals
        ? [
            stats.totals.conversions.count,
            stats.totals.conversions.change,
            stats.rows.map((stat) => stat.conversionCount),
          ]
        : calculateTopChartData(previewStats, 'conversions'),
    [stats]
  )
  const revenue = useMemo(
    () =>
      stats?.totals
        ? [
            stats.totals.commission.value,
            stats.totals.commission.change,
            stats.rows.map((stat) => stat.conversionTotalCommission),
          ]
        : calculateTopChartData(previewStats, 'revenue'),
    [stats]
  )
  const currency =
    stats?.totals?.commission?.currencyCode ||
    previewStats[0]?.currency ||
    'DKK'

  return (
    <div className="row m-t-6">
      <TopChart label="Clicks" data={clicks} color="#ffb000" />
      <TopChart label="Conversions" data={conversions} color="#6a98fd" />
      <TopChart
        label="Commission"
        currency={currency}
        data={revenue}
        color="#0ccfaf"
      />
    </div>
  )
}

const TopChart = (props) => {
  const { label, currency, data, color } = props

  const [value, trend, chartData] = data

  return (
    <div className="flex-1">
      <div className="row row-center row-space-between">
        <div>
          {label}
          {currency && <span className="text-light m-l-2">{currency}</span>}
        </div>
      </div>
      <div className="text-dark text-larger text-boldest m-t-15 m-b-3">
        {formatStat(value)}
      </div>
      <MiniAreaChart
        title={label}
        data={chartData}
        color={color}
        height={80}
        chartType="monotone"
      />
    </div>
  )
}

const InviteNotice = (props) => {
  const { contract, showSnackbar, connectedChannels } = props

  if (contract.pendingChanges) {
    const inviteLink = `${onboardHost}/changes/${contract.uuid || ''}`

    return (
      <div className="notice2-info">
        <span>
          You have changed the Terms of this campaign. The changes will be
          applied{' '}
          {contract.scheduleAt
            ? `automatically on ${moment(contract.scheduleAt).format(
                'MMMM DD'
              )}, or`
            : ''}{' '}
          when the new terms have been accepted by the Merchant. Here is the
          link to view and accept the new terms:{' '}
          <button
            className="invite-link text-left text-breakword"
            onClick={() => {
              copyToClipboard(inviteLink)
              showSnackbar('Copied to clipboard')
            }}
          >
            {inviteLink}
            <SVG src="/images/icon-copy.svg" className="flex-noshrink m-l-4" />
          </button>
          <br />
          Please <b>note</b> that this link only works for this specific
          Merchant.
        </span>
      </div>
    )
  } else {
    if (contract.formFinished) return null
    const isReady =
      contract.status === 'installed' || contract.status === 'running'
    if (isReady) {
      let trackingLink = `${backendHost}/u/${contract.smartlinkCode}`
      if (!contract.smartlinkCode) {
        const [firstChannel] = connectedChannels
        trackingLink = `${backendHost}/tracking/clicks/v1/${firstChannel?.channelUuid}?targetUrl=https%3A%2F%2F${contract.domain}`
      }
      return (
        <div className="notice2-info green">
          <span>
            The Merchant has installed and successfully tested tracking. You can
            now use this link to send traffic and track sales to the merchant:{' '}
            <button
              className="invite-link text-left text-breakword"
              onClick={() => {
                copyToClipboard(trackingLink)
                showSnackbar('Copied to clipboard')
              }}
            >
              {trackingLink}
              <SVG
                src="/images/icon-copy.svg"
                className="flex-noshrink m-l-4"
              />
            </button>
          </span>
        </div>
      )
    } else {
      const isPending =
        contract.status === 'pending' ||
        contract.status === 'sent' ||
        contract.status === 'signed'
      if (!isPending) return null
      const inviteLink = `${onboardHost}/signup/${contract.uuid || ''}`
      return (
        <div className="notice2-info">
          <span>
            The Merchant has received an invitation to partner up with you. If
            you wish to send it manually, you can use this invitation link:{' '}
            <button
              className="invite-link text-left text-breakword"
              onClick={() => {
                copyToClipboard(inviteLink)
                showSnackbar('Copied to clipboard')
              }}
            >
              {inviteLink}
              <SVG
                src="/images/icon-copy.svg"
                className="flex-noshrink m-l-4"
              />
            </button>
            <br />
            Please <b>note</b> that this link only works for this specific
            Merchant.
          </span>
        </div>
      )
    }
  }
}

const Status = (props) => {
  const { status, tuneStatus } = props

  let label = statusLabels[status] || capitalize(status)
  if (tuneStatus === 'failed') label = 'Error'

  return (
    <div
      className={varClass({
        'badge text-smaller text-bolder': true,
        'text-light': status === 'pending' || status === 'ended',
        'text-new-orange': status === 'sent' || status === 'installed',
        'text-new-blue': status === 'signed' || status === 'running',
        'text-new-blood': tuneStatus === 'failed',
      })}
    >
      Status: {label}
    </div>
  )
}

const NewUserModal = (props) => {
  const { partner } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')
  const form = useForm({
    shouldFocusError: false,
  })
  const { register, handleSubmit } = form

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    const currentUser = getCurrentUser()
    const response = await connectPost(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/users`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Create new user</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row m-b-5">
            <div className="control flex-1">
              <label>Name</label>
              <input
                className="w-100"
                {...register('name', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Email</label>
              <input
                className="w-100"
                {...register('email', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Password</label>
              <input
                className="w-100"
                type="password"
                {...register('password', { required: true })}
              />
            </div>
          </div>

          <div className="row row-center row-space-between m-t-8">
            <span className="text-red m-b-2">{error || ''}</span>
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const NewCampaignModal = (props) => {
  const { partner, currencies, hash } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')
  const form = useForm<any>({
    defaultValues: {
      cookiePeriodDays: '30',
    },
    shouldFocusError: false,
  })
  const { register, handleSubmit, control, setValue, formState } = form
  const [cpsCommission, setCpsCommssion] = useState(false)
  const [noCommission, setNoCommssion] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    if (!showOptions) {
      delete values.couponCode
      delete values.requireCouponCode
      delete values.cookiePeriodDays
      delete values.attributionModel
      delete values.deeplinkParams
      delete values.domainAliases
      delete values.creatorOptions
    } else {
      values.requireCouponCode = !!values.requireCouponCode
    }

    const currentUser = getCurrentUser()

    values.endsAt = values.endsAt
      ? moment(values.endsAt).format('YYYY-MM-DD')
      : ''
    values.createdBy = `${currentUser.user.userFirstname} ${currentUser.user.userLastname}`
    values.createdByEmail = currentUser.user.userEmail || ''

    const response = await connectPost(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Create new campaign</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)} className="new-campaign-form">
          <p className="m-b-6">
            Please choose the terms of your partnership with {partner.name}
          </p>
          <div className="row m-b-5">
            <div className="control control-date flex-1-2">
              <label>Start date</label>
              <Controller
                render={({ field: { onChange, value, name, ref } }) => (
                  <DateInput
                    innerRef={ref}
                    selected={value}
                    onChange={(newDate) => {
                      onChange(newDate)
                    }}
                    highlightDates={[new Date()]}
                    minDate={new Date()}
                  />
                )}
                name="startsAt"
                rules={{ required: true }}
                control={control}
                defaultValue={new Date()}
              />
            </div>
            <div className="control control-date flex-1-2">
              <label>End date</label>
              <Controller
                render={({ field: { onChange, value, name, ref } }) => (
                  <DateInput
                    innerRef={ref}
                    selected={value}
                    onChange={(newDate) => {
                      onChange(newDate)
                    }}
                    highlightDates={[new Date()]}
                    minDate={new Date()}
                    blankValue="No end date"
                    isClearable
                  />
                )}
                name="endsAt"
                control={control}
                defaultValue={undefined}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div
              className={varClass({
                'control flex-1-2': true,
                'has-error': formState?.errors?.name,
              })}
            >
              <label>Campaign name</label>
              <input
                className="w-100"
                {...register('name', { required: true })}
              />
            </div>
            <div
              className={varClass({
                'control flex-1-2': true,
                'has-error': formState?.errors?.url,
              })}
            >
              <label>URL</label>
              <input
                className="w-100"
                {...register('url', { required: true, pattern: /\w+\.\w+/ })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div
              className={varClass({
                'control control-select flex-1': true,
                'has-error': formState?.errors?.commissionType,
              })}
            >
              <label>Commission model</label>
              <select
                className="w-100"
                {...register('commissionType', {
                  required: true,
                  validate: (value) => value !== 'Choose',
                })}
                onChange={(event) => {
                  if (event.target.value === 'NoCommission') {
                    form.setValue('commissionValue', '0')
                    form.setValue('commissionCurrency', 'Choose')
                    form.unregister('commissionValue')
                    form.unregister('commissionCurrency')
                    setCpsCommssion(false)
                    setNoCommssion(true)
                  } else {
                    form.unregister('commissionValue')
                    form.unregister('commissionCurrency')
                    setCpsCommssion(event.target.value === 'CPS')
                    setNoCommssion(false)
                  }
                }}
              >
                <option>Choose</option>
                <option value="NoCommission">No commission</option>
                <option value="CPA">CPA - fixed fee</option>
                <option value="CPS">CPS - percentage of sale</option>
                <option value="CPC">CPC - cost per click</option>
              </select>
            </div>
          </div>
          <div className="row m-b-5">
            <div
              className={varClass({
                'control flex-1-2': true,
                'control-focusless': noCommission,
                'has-error': formState?.errors?.commissionValue,
              })}
            >
              <label>Commission</label>
              <input
                className={varClass({
                  'w-100': true,
                  'read-only': noCommission,
                })}
                readOnly={noCommission}
                {...register('commissionValue', {
                  required: !noCommission,
                  pattern: /^\d+([,.]\d+)?$/,
                })}
              />
              <span
                className={varClass({
                  'label-percent': true,
                  'shown': cpsCommission,
                })}
              >
                %
              </span>
            </div>
            <div
              className={varClass({
                'control control-select flex-1-2': true,
                'control-focusless': noCommission,
                'has-error': formState?.errors?.commissionCurrency,
              })}
            >
              <label>Currency</label>
              <select
                className={varClass({
                  'w-100': true,
                  'read-only': noCommission,
                })}
                disabled={noCommission}
                {...register('commissionCurrency', {
                  required: !noCommission,
                  validate: (value) => value !== 'Choose',
                })}
              >
                <option>Choose</option>
                {map(currencies, (currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-checkbox flex-1">
              <input type="checkbox" {...register('disableDeeplinks')} />
              <label>Disable deeplinking</label>
            </div>
          </div>

          <div className="advanced-options">
            <button
              className="link p-x-0 m-b-5"
              type="button"
              onClick={(event) => {
                event.preventDefault()
                if (showOptions) {
                  setShowOptions(false)
                  setValue('couponCode', '')
                  setValue('requireCouponCode', false)
                  setValue('cookiePeriodDays', 30)
                  setValue('attributionModel', 'default')
                  setValue('deeplinkParams', '')
                  setValue('domainAliases', '')
                } else {
                  setShowOptions(true)
                }
              }}
            >
              <SVG
                src="/images/icon-cog.svg"
                className={varClass({
                  'advanced-options-icon': true,
                  'smooth-rotate-0': !showOptions,
                  'smooth-rotate-90': showOptions,
                })}
              />
              {showOptions
                ? 'Disable advanced options'
                : 'Show advanced options'}
            </button>
          </div>

          <SmoothCollapse className="advanced-options" expanded={showOptions}>
            <div className="advanced-options-body">
              <div className="row m-b-5">
                <div className="control flex-1">
                  <label>Coupon code</label>
                  <input className="w-100" {...register('couponCode')} />
                </div>
              </div>
              <div className="row m-b-5">
                <div className="control control-checkbox flex-1">
                  <input type="checkbox" {...register('requireCouponCode')} />
                  <label>Reject conversions without this coupon code</label>
                </div>
              </div>
              <div className="row m-b-5">
                <div
                  className={varClass({
                    'control flex-1-3': true,
                    'has-error': formState?.errors?.cookiePeriodDays,
                  })}
                >
                  <label>Cookie period</label>
                  <input
                    className="w-100"
                    {...register('cookiePeriodDays', {
                      required: true,
                      pattern: /^\d+$/,
                    })}
                  />
                </div>
                <div className="label-days">&nbsp;days</div>
              </div>
              <div className="row">
                <div className="control control-select flex-1">
                  <label>Attribution model</label>
                  <select className="w-100" {...register('attributionModel')}>
                    <option value="default">
                      Attribute all in cookie period (default)
                    </option>
                    <option value="lastKnown">
                      Last click from known sources
                    </option>
                    <option value="session">
                      Last click from all sources (only track on current
                      session)
                    </option>
                  </select>
                </div>
              </div>
              <a
                href="https://docs.heylink.com/outbound/attribution-models"
                className="link text-small m-t-2 m-b-6"
                target="_blank"
              >
                About attribution models
              </a>
              <div className="row m-b-6">
                <div className="flex-1">
                  <label>
                    Custom URL parameters
                    <WithTooltip
                      className="campaign-form-info inline-block m-l-1"
                      title="Make sure deeplinking is enabled"
                      text={<SVG src="/images/recommendations/icon-info.svg" />}
                      forceShow
                    />
                  </label>
                  <CampaignDeeplinkParamsForm
                    field="deeplinkParams"
                    setValue={setValue}
                  />
                </div>
              </div>
              <div className="row m-b-6">
                <div className="flex-1">
                  <label>Additional tracking domains</label>
                  <CampaignDomainAliasesForm
                    field="domainAliases"
                    setValue={setValue}
                  />
                </div>
              </div>
              <div className="row">
                <div className="flex-1">
                  <CampaignCreatorOptionsForm
                    field="creatorOptions"
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>
          </SmoothCollapse>

          <div className="row row-center row-space-between m-t-8">
            <span className="text-red m-b-2">{error || ''}</span>
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const EditCampaignModal = (props) => {
  const { partner, contract, currencies, hash } = props

  const { hideModal } = useModal()

  const defaultValues = {
    startsAt:
      contract?.status === 'ended' ? new Date() : new Date(contract.startsAt),
    endsAt:
      contract.endsAt && contract.endsAt !== 'Invalid date'
        ? new Date(contract.endsAt)
        : undefined,
    name: contract.name,
    url: contract.url,
    commissionType: contract.commissionType,
    commissionValue: contract.commissionFlat || contract.commissionPercentage,
    commissionCurrency: contract.currency,
    disableDeeplinks: contract.disableDeeplinks || false,
    couponCode: contract.couponCode || '',
    requireCouponCode: contract.requireCouponCode || false,
    cookiePeriodDays: contract.trackingPeriod || 30,
    attributionModel: contract.customAttributionModel || 'default',
    deeplinkParams: contract.customDeeplinkParams || '',
    domainAliases: contract.customDomainAliases || '',
  }

  const [showSchedule, setShownSchedule] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(
    contract.scheduleAt
      ? moment(contract.scheduleAt).toDate()
      : moment().add(1, 'day').startOf('day').toDate()
  )

  const [error, setError] = useState('')
  const form = useForm({
    defaultValues,
    shouldFocusError: false,
  })
  const { register, handleSubmit, control, setValue, formState } = form
  const [cpsCommission, setCpsCommssion] = useState(
    contract.commissionType === 'CPS'
  )
  const [noCommission, setNoCommssion] = useState(
    contract.commissionType === 'NoCommission'
  )
  const [showOptions, setShowOptions] = useState(false)

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    values.disableDeeplinks = !!values.disableDeeplinks
    values.requireCouponCode = !!values.requireCouponCode

    const currentUser = getCurrentUser()

    values.endsAt = values.endsAt
      ? moment(values.endsAt).format('YYYY-MM-DD')
      : ''
    values.createdBy = `${currentUser.user.userFirstname} ${currentUser.user.userLastname}`
    values.createdByEmail = currentUser.user.userEmail || ''

    if (contract?.status === 'ended') {
      const response = await connectPatch(
        `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/${contract.uuid}`
      )
      if (response.code !== 200) {
        setError(responseError(response))
        return
      }
    }

    if (showSchedule) {
      values.scheduleAt = moment(scheduleDate).format('YYYY-MM-DD')
    }

    const response = await connectPut(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/${contract.uuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Update campaign</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)} className="new-campaign-form">
          {!showSchedule ? (
            <>
              <p className="m-b-6">
                Please adjust the terms of your partnership with {partner.name}
                {contract.scheduleAt ? (
                  <>
                    <br />
                    Changes will apply automatically on{' '}
                    {moment(contract.scheduleAt).format('MMMM DD')}
                  </>
                ) : null}
              </p>
              <div className="row m-b-5">
                <div className="control control-date flex-1-2">
                  <label>Start date</label>
                  <Controller
                    render={({ field: { onChange, value, name, ref } }) => (
                      <DateInput
                        innerRef={ref}
                        selected={value}
                        onChange={(newDate) => {
                          onChange(newDate)
                        }}
                        highlightDates={[new Date()]}
                        minDate={new Date(contract.startsAt)}
                      />
                    )}
                    name="startsAt"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={new Date()}
                  />
                </div>
                <div className="control control-date flex-1-2">
                  <label>End date</label>
                  <Controller
                    render={({ field: { onChange, value, name, ref } }) => (
                      <DateInput
                        innerRef={ref}
                        selected={value}
                        onChange={(newDate) => {
                          onChange(newDate)
                        }}
                        highlightDates={[new Date()]}
                        minDate={new Date()}
                        blankValue="No end date"
                        isClearable
                      />
                    )}
                    name="endsAt"
                    control={control}
                    defaultValue={undefined}
                  />
                </div>
              </div>
              <div className="row m-b-5">
                <div
                  className={varClass({
                    'control flex-1-2': true,
                    'has-error': formState?.errors?.name,
                  })}
                >
                  <label>Campaign name</label>
                  <input
                    className="w-100"
                    {...register('name', { required: true })}
                  />
                </div>
                <div
                  className={varClass({
                    'control flex-1-2': true,
                    'has-error': formState?.errors?.url,
                  })}
                >
                  <label>URL</label>
                  <input
                    className="w-100"
                    {...register('url', {
                      required: true,
                      pattern: /\w+\.\w+/,
                    })}
                  />
                </div>
              </div>
              <div className="row m-b-5">
                <div
                  className={varClass({
                    'control control-select flex-1': true,
                    'has-error': formState?.errors?.commissionType,
                  })}
                >
                  <label>Commission model</label>
                  <select
                    className="w-100"
                    defaultValue={contract.commissionType}
                    {...register('commissionType', {
                      required: true,
                      validate: (value) => value !== 'Choose',
                    })}
                    onChange={(event) => {
                      if (event.target.value === 'NoCommission') {
                        form.setValue('commissionValue', '0')
                        form.setValue('commissionCurrency', 'Choose')
                        form.unregister('commissionValue')
                        form.unregister('commissionCurrency')
                        setCpsCommssion(false)
                        setNoCommssion(true)
                      } else {
                        form.unregister('commissionValue')
                        form.unregister('commissionCurrency')
                        setCpsCommssion(event.target.value === 'CPS')
                        setNoCommssion(false)
                      }
                    }}
                  >
                    <option>Choose</option>
                    <option value="NoCommission">No commission</option>
                    <option value="CPA">CPA - fixed fee</option>
                    <option value="CPS">CPS - percentage of sale</option>
                    <option value="CPC">CPC - cost per click</option>
                  </select>
                </div>
              </div>
              <div className="row m-b-5">
                <div
                  className={varClass({
                    'control flex-1-2': true,
                    'control-focusless': noCommission,
                    'has-error': formState?.errors?.commissionValue,
                  })}
                >
                  <label>Commission</label>
                  <input
                    className={varClass({
                      'w-100': true,
                      'read-only': noCommission,
                    })}
                    readOnly={noCommission}
                    {...register('commissionValue', {
                      required: !noCommission,
                      pattern: /^\d+([,.]\d+)?$/,
                    })}
                  />
                  <span
                    className={varClass({
                      'label-percent': true,
                      'shown': cpsCommission,
                    })}
                  >
                    %
                  </span>
                </div>
                <div
                  className={varClass({
                    'control control-select flex-1-2': true,
                    'control-focusless': noCommission,
                    'has-error': formState?.errors?.commissionCurrency,
                  })}
                >
                  <label>Currency</label>
                  <select
                    className={varClass({
                      'w-100': true,
                      'read-only': noCommission,
                    })}
                    defaultValue={contract.commissionCurrency}
                    disabled={noCommission}
                    {...register('commissionCurrency', {
                      required: !noCommission,
                      validate: (value) => value !== 'Choose',
                    })}
                  >
                    <option>Choose</option>
                    {map(currencies, (currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row m-b-5">
                <div className="control control-checkbox flex-1">
                  <input type="checkbox" {...register('disableDeeplinks')} />
                  <label>Disable deeplinking</label>
                </div>
              </div>

              <div className="advanced-options">
                <button
                  className="link p-x-0 m-b-5"
                  type="button"
                  onClick={(event) => {
                    event.preventDefault()
                    if (showOptions) {
                      setShowOptions(false)
                    } else {
                      setShowOptions(true)
                    }
                  }}
                >
                  <SVG
                    src="/images/icon-cog.svg"
                    className={varClass({
                      'advanced-options-icon': true,
                      'smooth-rotate-0': !showOptions,
                      'smooth-rotate-90': showOptions,
                    })}
                  />
                  {showOptions
                    ? 'Hide advanced options'
                    : 'Show advanced options'}
                </button>
              </div>

              <SmoothCollapse
                className="advanced-options"
                expanded={showOptions}
              >
                <div className="advanced-options-body">
                  <div className="row m-b-5">
                    <div className="control flex-1">
                      <label>Coupon code</label>
                      <input className="w-100" {...register('couponCode')} />
                    </div>
                  </div>
                  <div className="row m-b-5">
                    <div className="control control-checkbox flex-1">
                      <input
                        type="checkbox"
                        {...register('requireCouponCode')}
                      />
                      <label>Reject conversions without this coupon code</label>
                    </div>
                  </div>
                  <div className="row m-b-5">
                    <div
                      className={varClass({
                        'control flex-1-3': true,
                        'has-error': formState?.errors?.cookiePeriodDays,
                      })}
                    >
                      <label>Cookie period</label>
                      <input
                        className="w-100"
                        {...register('cookiePeriodDays', {
                          required: true,
                          pattern: /^\d+$/,
                        })}
                      />
                    </div>
                    <div className="label-days">&nbsp;days</div>
                  </div>
                  <div className="row">
                    <div className="control control-select flex-1">
                      <label>Attribution model</label>
                      <select
                        className="w-100"
                        {...register('attributionModel')}
                      >
                        <option value="default">
                          Attribute all in cookie period (default)
                        </option>
                        <option value="lastKnown">
                          Last click from known sources
                        </option>
                        <option value="session">
                          Last click from all sources (only track on current
                          session)
                        </option>
                      </select>
                    </div>
                  </div>
                  <a
                    href="https://docs.heylink.com/outbound/attribution-models"
                    className="link text-small m-t-2 m-b-6"
                    target="_blank"
                  >
                    About attribution models
                  </a>
                  <div className="row m-b-6">
                    <div className="flex-1">
                      <label>
                        Custom URL parameters
                        <WithTooltip
                          className="campaign-form-info inline-block m-l-1"
                          title="Make sure deeplinking is enabled"
                          text={
                            <SVG src="/images/recommendations/icon-info.svg" />
                          }
                          forceShow
                        />
                      </label>
                      <CampaignDeeplinkParamsForm
                        field="deeplinkParams"
                        setValue={setValue}
                        initialValue={contract.customDeeplinkParams}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="flex-1">
                      <label>Additional tracking domains</label>
                      <CampaignDomainAliasesForm
                        field="domainAliases"
                        setValue={setValue}
                        initialValue={contract.customDomainAliases}
                      />
                    </div>
                  </div>
                  {/*
                  <div className="row">
                    <div className="flex-1">
                      <CampaignCreatorOptionsForm
                        field="creatorOptions"
                        setValue={setValue}
                        initialValue={contract.customCreatorOptions}
                      />
                    </div>
                  </div>
                  */}
                </div>
              </SmoothCollapse>

              <div className="row row-center row-space-between m-t-8">
                <span className="text-red m-b-2">{error || ''}</span>
                <div className="row row-nowrap">
                  <button
                    className="btn btn-bordered"
                    type="button"
                    disabled={submitting}
                    onClick={() => setShownSchedule(true)}
                  >
                    Schedule
                  </button>
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={submitting}
                  >
                    Update
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="m-b-6">
                When to apply adjusted terms of your partnership with{' '}
                {partner.name}?
              </p>
              <div className="row m-b-5">
                <div className="control control-date w-100">
                  <label>Schedule terms update</label>
                  <DateInput
                    selected={scheduleDate}
                    onChange={(newDate: Date) => {
                      setScheduleDate(newDate)
                    }}
                    highlightDates={[new Date()]}
                    minDate={new Date()}
                  />
                </div>
              </div>

              <div
                className="row row-center row-space-between"
                style={{ marginTop: 236 }}
              >
                <span className="text-red m-b-2">{error || ''}</span>
                <div className="row row-nowrap">
                  <button
                    className="btn btn-bordered"
                    type="button"
                    disabled={submitting}
                    onClick={() => setShownSchedule(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={submitting}
                  >
                    Schedule update
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  )
}

const DeleteCampaignModal = (props) => {
  const { partner, contract } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const submit = async (values) => {
    if (submitting) return

    setSubmitting(true)
    setError('')

    const currentUser = getCurrentUser()

    const response = await connectDelete(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/${contract.uuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }

    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">End campaign</div>
      </div>
      <div className="card-body">
        <p className="m-b-6">
          Are you sure you want to terminate campaign <b>{contract.name}</b>{' '}
          with {partner.name}?
        </p>
        <div className="row row-center row-space-between">
          <div className="text-red">{error || ''}</div>
          <div className="text-right">
            <button
              onClick={submit}
              className="btn btn-danger"
              disabled={submitting}
            >
              Yes
            </button>
            <button onClick={hideModal} className="btn m-l-2">
              No
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const CampaignDeeplinkParamsForm = (props) => {
  const { setValue, field, initialValue } = props

  const newId = () => Math.random().toString().substr(2)

  const [values, setValues] = useState(() =>
    (initialValue || '').split(',').reduce((result, pair) => {
      const [key, value] = pair.split('=')
      if (key && value) {
        result.push({ id: newId(), key, value })
      }
      return result
    }, [])
  )
  const updateFormValue = (newValues: { key: string; value: string }[]) => {
    const outValue = newValues
      .filter((pair) => pair.key.trim() && pair.value.trim())
      .map((pair) => `${pair.key.trim()}=${pair.value.trim()}`)
      .join(',')
    setValue(field, outValue)
  }
  const addPair = () => {
    const newValues = [...values, { id: newId(), key: '', value: '' }]
    setValues(newValues)
    updateFormValue(newValues)
  }
  const removePair = (index: number) => () => {
    const newValues = [...values]
    newValues.splice(index, 1)
    setValues(newValues)
    updateFormValue(newValues)
  }
  const editPair = (index: number, field: string) => (event) => {
    const newValues = [...values]
    newValues[index][field] = event.target.value
    setValues(newValues)
    updateFormValue(newValues)
  }

  return (
    <div>
      <div className="row row-end m-t--6 m-b-2">
        <a
          href="#"
          className="btn btn-md vertical-middle text-bold"
          onClick={addPair}
        >
          <SVG
            src="/images/icon-plus2-color.svg"
            className="m-r-1"
            style={{ position: 'relative', top: -1 }}
          />
          Add custom parameter
        </a>
      </div>

      {map(values, (pair, index) => (
        <div key={pair.id} className="row row-center row-nowrap m-b-2">
          <div className="flex-1 control">
            <input
              name={`${pair.id}-key`}
              value={pair.key}
              onChange={editPair(index, 'key')}
              placeholder="utm_source"
              className="w-100"
            />
          </div>
          <div className="flex-0">=</div>
          <div className="flex-1 control">
            <input
              name={`${pair.id}-value`}
              value={pair.value}
              onChange={editPair(index, 'value')}
              placeholder="my-value"
              className="w-100"
            />
          </div>
          <div className="flex-0">
            <a
              href="#"
              className="btn btn-link text-new-blood"
              onClick={removePair(index)}
            >
              <SVG src="/images/icon-times.svg" width={12} />
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

const CampaignDomainAliasesForm = (props) => {
  const { setValue, field, initialValue } = props

  const newId = () => Math.random().toString().substr(2)

  const [values, setValues] = useState(() =>
    (initialValue || '').split(',').reduce((result, value) => {
      if (value) {
        result.push({ id: newId(), value })
      }
      return result
    }, [])
  )
  const updateFormValue = (newValues: { value: string }[]) => {
    const outValue = newValues
      .map((entry) => entry.value.trim())
      .filter((value) => value)
      .join(',')
    setValue(field, outValue)
  }
  const addValue = () => {
    const newValues = [...values, { id: newId(), value: '' }]
    setValues(newValues)
    updateFormValue(newValues)
  }
  const removeValue = (index: number) => () => {
    const newValues = [...values]
    newValues.splice(index, 1)
    setValues(newValues)
    updateFormValue(newValues)
  }
  const editValue = (index: number) => (event) => {
    const newValues = [...values]
    newValues[index].value = event.target.value
    setValues(newValues)
    updateFormValue(newValues)
  }

  return (
    <div>
      <div className="row row-end m-t--6 m-b-2">
        <a
          href="#"
          className="btn btn-md vertical-middle text-bold"
          onClick={addValue}
        >
          <SVG
            src="/images/icon-plus2-color.svg"
            className="m-r-1"
            style={{ position: 'relative', top: -1 }}
          />
          Add domain
        </a>
      </div>

      {map(values, (entry, index) => (
        <div key={entry.id} className="row row-center row-nowrap m-b-2">
          <div className="flex-1 control">
            <input
              name={`${entry.id}-value`}
              value={entry.value}
              onChange={editValue(index)}
              placeholder="example.com"
              className="w-100"
            />
          </div>
          <div className="flex-0">
            <a
              href="#"
              className="btn btn-link text-new-blood"
              onClick={removeValue(index)}
            >
              <SVG src="/images/icon-times.svg" width={12} />
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

const CampaignCreatorOptionsForm = (props) => {
  const { field, setValue, initialValue } = props

  const [initialCreatorsDefault, initialCreatorsOnly] = useMemo(() => {
    const split: string[] = (initialValue || '').split(',')
    const creatorsDefault = split.shift() === '1'
    const creatorsOnly = split.shift() === '1'
    return [creatorsDefault, creatorsOnly]
  }, [initialValue])

  const [creatorsDefault, setCreatorsDefault] = useState(initialCreatorsDefault)
  const [creatorsOnly, setCreatorsOnly] = useState(initialCreatorsOnly)

  useEffect(() => {
    const outValue: string[] = [
      'v2',
      creatorsDefault ? '1' : '0',
      creatorsOnly ? '1' : '0',
    ]
    setValue(field, outValue.join(','))
  }, [creatorsDefault, creatorsOnly])

  return (
    <div>
      <div className="m-b-2">
        <div className="control control-checkbox">
          <input
            type="checkbox"
            checked={creatorsDefault}
            onChange={(event) => setCreatorsDefault(event.target.checked)}
          />
          <label>Default creator campaign</label>
        </div>
        <span
          data-tip="Creators will be mapped to this campaign by default"
          data-for="creator-tooltip"
          className="m-l-1"
        >
          <SVG src="/images/recommendations/icon-info.svg" />
        </span>
      </div>

      <div className="m-b-2">
        <div className="control control-checkbox">
          <input
            type="checkbox"
            checked={creatorsOnly}
            onChange={(event) => setCreatorsOnly(event.target.checked)}
          />
          <label>Creators only</label>
        </div>
        <span
          data-tip="Only clicks from creators will be able to use this campaign"
          data-for="creator-tooltip"
          className="m-l-1"
        >
          <SVG src="/images/recommendations/icon-info.svg" />
        </span>
      </div>

      <ReactTooltip
        id="creator-tooltip"
        type="dark"
        effect="solid"
        place="right"
      />
    </div>
  )
}

const DeletePartnerModal = (props) => {
  const { partner } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')

  const [confirm, setConfirm] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const submit = async (values) => {
    if (submitting) return

    setSubmitting(true)
    setError('')

    const currentUser = getCurrentUser()

    const response = await connectDelete(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}`
    )

    if (response) {
      if (response.code === 200) {
        navigateTo('/partners')
        hideModal()
      } else {
        setError(responseError(response))
      }
    }

    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete partner</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-4">
          <SVG src="/images/notice-info.svg" className="m-r-2" />
          You are about to delete a Direct partner.
          <br />
          This will cause all campaigns from this partner to stop running.
        </div>
        <div className="control control-checkbox m-b-8">
          <input
            type="checkbox"
            checked={confirm}
            onChange={(event) => setConfirm(event.target.checked)}
          />
          <label>
            Yes — I'm sure i want to delete this partner and stop all campaigns.
          </label>
        </div>

        <div className="row row-center row-space-between">
          <div className="text-red">{error || ''}</div>
          <div className="text-right">
            <button
              onClick={submit}
              className="btn btn-danger"
              disabled={!confirm || submitting}
            >
              Yes
            </button>
            <button onClick={hideModal} className="btn m-l-2">
              No
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const EditPartnerModal = (props) => {
  const { partner } = props
  const { company, email, uuid } = partner
  const {
    name,
    address,
    zip,
    city,
    country,
    vat,
    contactName,
    contactTitle,
    contactPhone,
  } = company
  const [firstName, lastName] = (contactName || '').split(' ')
  const { hideModal } = useModal()
  const defaultValues = {
    companyName: name,
    companyAddress: address,
    companyZip: zip,
    companyCity: city,
    companyCountry: country,
    companyVat: vat,
    contactFirstName: firstName || '',
    contactLastName: lastName || '',
    contactTitle,
    contactEmail: email,
    contactPhone,
  }

  const [error, setError] = useState('')
  const form = useForm({
    defaultValues,
    shouldFocusError: false,
  })
  const { register, handleSubmit, formState, control } = form
  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    if (!values.companyAddress.match(/\w/)) {
      setError('Valid address is required')
      return
    }

    const currentUser = getCurrentUser()
    const response = await connectPut(
      `/publishers/${currentUser.account?.accountUuid}/${uuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }
  })

  const placeholderIsRequired = (key: string) => {
    if (formState.errors?.[key]?.type === 'required') {
      return 'This field is required'
    } else {
      return null
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Update partner</div>
      </div>
      <div className="card-body">
        <form className="new-partner-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Company name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyName')}
                {...register('companyName', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Address</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyAddress')}
                {...register('companyAddress', {
                  required: true,
                  minLength: 3,
                })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Postal code</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyZip')}
                {...register('companyZip', { required: true, minLength: 3 })}
              />
            </div>
            <div className="control flex-1-2">
              <label>City</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyCity')}
                {...register('companyCity', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-select flex-1-2">
              <label>Country</label>
              <select
                className="w-100"
                {...register('companyCountry', { required: true })}
              >
                <option value="">
                  {formState.errors?.companyCountry?.type === 'required'
                    ? 'This field is required'
                    : 'Choose country'}
                </option>
                <CountrySelectOptions />
              </select>
            </div>
            <div className="control flex-1-2">
              <label>VAT number</label>
              <input className="w-100" {...register('companyVat')} />
            </div>
          </div>
          <div className="text-dark text-bold m-t-30 m-b-4">Contact person</div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>First name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactFirstName')}
                {...register('contactFirstName', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Last name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactLastName')}
                {...register('contactLastName', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Title</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactTitle')}
                {...register('contactTitle', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>E-mail</label>
              <input
                type="email"
                className="w-100"
                autoComplete="off"
                placeholder={placeholderIsRequired('contactEmail')}
                {...register('contactEmail', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-phone flex-1-2">
              <label>Phone number</label>
              <Controller
                control={control}
                name="contactPhone"
                render={(props) => (
                  <CountryPhoneInput
                    name={props.field.name}
                    initialCode={''}
                    initialPhone={''}
                    onChange={(values) => {
                      props.field.onChange(values.code + values.phone)
                    }}
                    dropdownOnTop
                  />
                )}
              />
            </div>
          </div>
          <div className="row row-center row-space-between m-t-8">
            <span className="text-red m-b-2">{error || ''}</span>
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const DeleteUserModal = (props) => {
  const { user, partner } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const submit = async (values) => {
    if (submitting) return

    setSubmitting(true)
    setError('')

    const currentUser = getCurrentUser()

    const response = await connectDelete(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/users/${user.uuid}`
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }

    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete user</div>
      </div>
      <div className="card-body">
        <div>
          Are you sure you want to delete this user <b>{user.email}</b>?
        </div>

        <div className="row row-center row-space-between m-t-3">
          <div className="text-red">{error || ''}</div>
          <div className="text-right">
            <button
              onClick={submit}
              className="btn btn-danger"
              disabled={submitting}
            >
              Yes
            </button>
            <button onClick={hideModal} className="btn m-l-2">
              No
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const UpdateUserModal = (props) => {
  const { user, partner } = props

  const defaultValues = {
    name: user.name,
    email: user.email,
    password: '',
  }

  const { hideModal } = useModal()
  const form = useForm({
    defaultValues,
    shouldFocusError: false,
  })

  const [error, setError] = useState('')
  const { register, handleSubmit } = form
  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    const currentUser = getCurrentUser()
    const response = await connectPut(
      `/publishers/${currentUser.account?.accountUuid}/${partner.uuid}/users/${user.uuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        navigateReload()
        hideModal()
      } else {
        setError(responseError(response))
      }
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Update user</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row m-b-5">
            <div className="control flex-1">
              <label>Name</label>
              <input
                className="w-100"
                {...register('name', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Email</label>
              <input
                className="w-100"
                {...register('email', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Password</label>
              <input
                className="w-100"
                type="password"
                {...register('password', { required: true })}
              />
            </div>
          </div>

          <div className="row row-center row-space-between m-t-8">
            <span className="text-red m-b-2">{error || ''}</span>
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
